import {
  ReactElement,
  createContext,
  useContext,
  useState,
  useEffect,
  useReducer,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { UploadedFile } from '@la/ds-ui-components';
import {
  BFFMember,
  FormField,
  FormFieldResponse,
  BFFGroupAccount,
  GroupAccountUser,
  Registration,
  RegistrationDecodedParams,
  RegistrationWorkflowWaiver,
  RegistrationStatus,
  Team,
  RegistrationType,
  ProgramRole,
} from '@la/types';
import {
  formatFormFieldsForWorkflow,
  isPlayerRegistration,
  isStaffRegistration,
} from '@la/utilities';
import { updateFormField } from 'lib/apis/getFormFields';
import { getStaffRoles } from 'lib/apis/getStaffRoles';
import {
  getUserRegistrations,
  getExistingUserRegistration,
} from 'lib/apis/getUserRegistrations';
import { updateWorkflowRegistration } from 'lib/apis/updateWorkflowRegistration';
import { uploadFile as uploadFileApi } from 'lib/apis/uploadFile';
import { DecodedTokenData } from 'lib/apis/verifyUrlSignature';
import { getLAHostnameParts } from 'lib/utils/urlUtils';
import {
  useFinalizeRegistrationsMutation,
  useGetExposeNGBMembershipFieldsQuery,
  useGetRegistrantInfoQuery,
} from 'redux/services/registrationApi';
import { useGetSiteSettingsQuery } from 'redux/services/siteInfo';
import { RegistrationOption } from 'redux/services/tournamentApi';
import { WaiverData } from 'domains/Checkout/Checkout.types';
import { getDecodedData } from './RegistrationContext.utils';
import {
  RegistrantState,
  RegistrantStateActions,
  registrantInitialState,
  registrantReducer,
} from './reducer';
import { useRegistrantSteps } from './steps';

type Program = {
  id?: number;
  masterProgramId: number;
  name: string;
  details: {
    hotelLinks?: string;
  };
};

export type RegistrantInfoPayload = {
  decodedData?: DecodedTokenData;

  formFields: FormFieldResponse;
  groupAccount: BFFGroupAccount | null;
  loggedInUserId: number;
  masterProgram: Program;
  memberFormFields: FormField[];
  program: Program;
  structuredStateEnabled: boolean;
  team: Team | null;
  waivers: WaiverData[];
};

export type FieldState = {
  value: string;
  required: boolean;
  error?: string;
};

export type FormFieldUpdateData = {
  customFields: FormField[];
  registrationId?: string;
  userId?: string;
};

export type FileUploadData = {
  file: UploadedFile;
  propertyDefinitionId: string;
  userId: string;
};

type MemberProfileSettings = {
  childBirthDateRequired: boolean;
  childEmailEnabled: boolean;
};

export type RegistrationContextProps = RegistrantState & {
  dispatch: React.Dispatch<RegistrantStateActions>;
  decodedData: RegistrationDecodedParams | null;
  formFields: FormFieldResponse;
  groupAccount: BFFGroupAccount | null;
  loggedInUserId: number;
  loggedInUserName: string;
  masterProgramName?: string;
  masterProgramId?: number;
  memberFormFields: FormField[];
  memberProfileSettings: MemberProfileSettings;
  ngbMembershipType?: string | null;
  registrationId?: string;
  structuredStateEnabled: boolean;
  teamName: string;
  tournamentId: string;
  hotelLink?: string;
  waivers: WaiverData[] | null;
  touched: boolean;
  setTouched: React.Dispatch<React.SetStateAction<boolean>>;
  waiversTouched: boolean;
  setWaiversTouched: React.Dispatch<React.SetStateAction<boolean>>;
  hasErrors: boolean;
  setHasErrors: React.Dispatch<React.SetStateAction<boolean>>;
  updateFormFields: (data: FormFieldUpdateData) => Promise<{}[]>;
  uploadFile: (data: FileUploadData) => Promise<{ uuid: string }>;
  updateWithExistingRegistration: (userId: string) => Promise<void>;
  roleName?: string;
  userRegistrations?: Registration[];
  registrationOptions: RegistrationOption[] | null;
  setRegistrationId: (registrationId: string) => void;
  setRegistrationOptions: (options: RegistrationOption[]) => void;
  subdomain: string;
  loading: boolean;
  ctx: ReturnType<typeof getRegistrantContext>;
  feeValue: string;
};

const getRegistrantContext = (
  existingRegistration: Registration | null | undefined,
  decodedData: RegistrationDecodedParams | undefined
) => {
  if (!existingRegistration && !decodedData) {
    return null;
  }

  if (existingRegistration) {
    return {
      siteId: existingRegistration.siteId,
      programId: existingRegistration.programId,
      teamId: existingRegistration.teamIdOg,
      roleId: existingRegistration.properties.find(
        (property) => property.name === 'programRoles'
      )?.values[0],
      type: existingRegistration.registrationType as RegistrationType,
    };
  }

  if (decodedData) {
    return {
      siteId: parseInt(decodedData.site ?? ''),
      programId: decodedData.prid,
      teamId: decodedData.team,
      roleId: decodedData.role,
      type: decodedData.type as RegistrationType,
    };
  }
};

const initialValues: RegistrationContextProps = {
  ...registrantInitialState,
  waivers: null,
  formFields: null,
  groupAccount: null,
  loggedInUserId: 0,
  loggedInUserName: '',
  memberFormFields: [],
  structuredStateEnabled: true,
  decodedData: null,
  ctx: null,
  tournamentId: '',
  masterProgramName: '',
  memberProfileSettings: {
    childBirthDateRequired: false,
    childEmailEnabled: false,
  },
  ngbMembershipType: undefined,
  registrationId: undefined,
  teamName: '',
  touched: false,
  setTouched: () => {},
  hasErrors: false,
  setHasErrors: () => {},
  dispatch: () => {},
  updateFormFields: async () => [],
  uploadFile: async () => ({ uuid: '' }),
  waiversTouched: false,
  setWaiversTouched: () => {},
  updateWithExistingRegistration: async () => {},
  registrationOptions: null,
  setRegistrationId: () => {},
  setRegistrationOptions: () => {},
  subdomain: '',
  loading: true,
  feeValue: '',
};

export const getProgramRole = (type: string) => {
  if (type.toLocaleUpperCase() === RegistrationType.Player) {
    return ProgramRole.Player;
  }

  if (type.toLocaleUpperCase() === RegistrationType.PlayerFreeAgent) {
    return ProgramRole.FreeAgent;
  }

  return undefined;
};

export const RegistrationContext =
  createContext<RegistrationContextProps>(initialValues);

export const RegistrationProvider = ({
  children,
}: {
  children: ReactElement;
}) => {
  const [state, dispatch] = useReducer(
    registrantReducer,
    registrantInitialState
  );

  const [searchParams] = useSearchParams();
  const { encodedToken, decodedParams, initialRegistrationId } = getDecodedData(
    searchParams.get('selectRegistrationData'),
    searchParams.get('registrationId')
  );
  const [touched, setTouched] = useState(false);
  const [waiversTouched, setWaiversTouched] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [roleName, setRoleName] = useState<string | undefined>();
  const [registrationId, setRegistrationId] = useState<string>(
    initialRegistrationId ?? ''
  );
  const [registrationOptions, setRegistrationOptions] = useState<
    RegistrationOption[] | null
  >(null);
  const [userRegistrations, setUserRegistrations] = useState<Registration[]>(
    []
  );
  const [params] = useState(decodedParams);
  const { subdomain } = getLAHostnameParts();

  const {
    data,
    isLoading,
    error: registrationError,
  } = useGetRegistrantInfoQuery(
    {
      encodedToken: !params ? encodedToken : undefined,
      decodedParams: params,
      siteDomain: subdomain,
      registrationId: initialRegistrationId ?? null,
    },
    {
      skip: !encodedToken && !params && !initialRegistrationId,
    }
  );

  const ctx = getRegistrantContext(
    data?.existingRegistration,
    params ?? data?.decodedData
  );

  const { data: ngbMembershipType, isLoading: ngbMembershipTypeIsLoading } =
    useGetExposeNGBMembershipFieldsQuery(
      {
        programId: ctx?.programId.toString(),
        subdomain: subdomain,
      },
      { skip: !ctx?.programId || !subdomain }
    );

  const siteId = ctx?.siteId;
  const { data: siteSettings, isLoading: siteSettingsIsLoading } =
    useGetSiteSettingsQuery(siteId ? siteId : 0, { skip: !siteId });

  useEffect(() => {
    if (data?.masterProgram?.id && subdomain) {
      getUserRegistrations({
        programId: data?.masterProgram?.id,
        siteDomain: subdomain,
      }).then((response) => {
        setUserRegistrations(response.userRegistrations);
      });
    }
  }, [data, subdomain, dispatch]);

  useEffect(() => {
    dispatch({
      type: 'SET_GROUP_ACCOUNT',
      payload: data?.groupAccount ?? null,
    });
  }, [data]);

  useEffect(() => {
    if (ctx && ctx.siteId && ctx.roleId && !isNaN(parseInt(ctx.roleId))) {
      getStaffRoles({ siteId: ctx.siteId.toString() })
        .then((staffRoles) => {
          const programStaffRole = staffRoles.find((staffRole) => {
            if (!ctx.roleId) {
              return false;
            }
            return staffRole.id === parseInt(ctx.roleId);
          })?.role;
          setRoleName(programStaffRole);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [ctx]);

  useEffect(() => {
    if (data?.existingRegistration) {
      const { existingRegistration } = data;
      const { waivers } = existingRegistration;
      if (
        existingRegistration.registrationStatus ===
        RegistrationStatus.Registered
      ) {
        const message = isPlayerRegistration(
          existingRegistration.registrationType
        )
          ? 'This player already has a completed registration. Please select a different player.'
          : 'You have already registered for this tournament.';
        dispatch({
          type: 'SET_EXISTING_REGISTRATION_ERROR',
          payload: message,
        });

        return;
      }
      dispatch({ type: 'SET_EXISTING_REGISTRATION_ERROR', payload: '' });

      if (isPlayerRegistration(existingRegistration.registrationType)) {
        const registeredUserId = existingRegistration.properties.find(
          (property) => {
            return property.name === 'registeredUserId';
          }
        )?.values[0];

        dispatch({
          type: 'SET_SELECTED_PLAYER',
          payload: registeredUserId ?? '',
        });
      }

      const { formFields: existingFormFields, waivers: existingWaivers } =
        existingRegistration;
      if (existingFormFields && existingFormFields.length) {
        const allFormFields: FormField[] = [
          ...(data.formFields?.nonFileUploadFormFields ?? []),
          ...(data.formFields?.fileUploadFormFields ?? []),
        ];

        existingFormFields.forEach((formField) => {
          const { formFieldId, values } = formField;
          const baseFormField = allFormFields.find(
            (field) => field.propertyDefinitionId === formFieldId
          );
          const type = baseFormField?.type;

          if (type) {
            if (type === 'FILE_UPLOAD') {
              if (baseFormField) {
                dispatch({
                  type: 'SET_FILE_UPLOAD_FORM_FIELD',
                  payload: {
                    id: formFieldId,
                    field: {
                      ...baseFormField,
                      value: {
                        file: null,
                        name: values[0],
                        uuid: values[1],
                      },
                    },
                  },
                });
              }
            } else {
              if (baseFormField) {
                let value;
                if (type === 'MULTIPLE_CHECKBOXES') {
                  const { items } = baseFormField;
                  value = values
                    .map((searchValue) => {
                      return items.find(
                        (item) =>
                          item.value === searchValue ||
                          item.name === searchValue
                      )?.itemId;
                    })
                    .filter((value) => !!value);
                } else if (type === 'PICK_LIST') {
                  const { items } = baseFormField;
                  const searchValue = values[0];
                  value = items.find(
                    (item) =>
                      item.value === searchValue || item.name === searchValue
                  )?.itemId;
                } else {
                  value = values[0];
                }

                dispatch({
                  type: 'SET_NON_FILE_UPLOAD_FORM_FIELD',
                  payload: {
                    id: formFieldId,
                    field: {
                      ...baseFormField,
                      value: value as any,
                    },
                  },
                });
              }
            }
          }
        });
      }

      if (existingWaivers && existingWaivers.length) {
        existingWaivers.forEach((waiver) => {
          if (waivers) {
            const currentWaiver = waivers.find(
              ({ waiverId }) => waiver.waiverId === waiverId
            );
            if (currentWaiver) {
              dispatch({
                type: 'SET_SIGNED_WAIVERS',
                payload: {
                  id: waiver.waiverId.toString(),
                  signed: true,
                },
              });
            }
          }
        });
      }
    }
  }, [data]);

  const updateStateFromRegistration = (
    existingRegistration: Registration | undefined
  ) => {
    if (!existingRegistration || !data) {
      return null;
    }
    if (
      existingRegistration.registrationStatus === RegistrationStatus.Registered
    ) {
      const message = isPlayerRegistration(
        existingRegistration.registrationType
      )
        ? 'This player already has a completed registration. Please select a different player.'
        : 'You have already registered for this tournament.';
      dispatch({
        type: 'SET_EXISTING_REGISTRATION_ERROR',
        payload: message,
      });

      return;
    }
    dispatch({ type: 'SET_EXISTING_REGISTRATION_ERROR', payload: '' });

    if (isPlayerRegistration(existingRegistration.registrationType)) {
      const registeredUserId = existingRegistration.properties.find(
        (property) => {
          return property.name === 'registeredUserId';
        }
      )?.values[0];

      dispatch({
        type: 'SET_SELECTED_PLAYER',
        payload: registeredUserId ?? '',
      });
    }

    const { formFields: existingFormFields, waivers: existingWaivers } =
      existingRegistration;
    if (existingFormFields && existingFormFields.length) {
      const allFormFields: FormField[] = [
        ...(data.formFields?.nonFileUploadFormFields ?? []),
        ...(data.formFields?.fileUploadFormFields ?? []),
      ];

      existingFormFields.forEach((formField) => {
        const { formFieldId, values } = formField;
        const baseFormField = allFormFields.find(
          (field) => field.propertyDefinitionId === formFieldId
        );
        const type = baseFormField?.type;

        if (type) {
          if (type === 'FILE_UPLOAD') {
            if (baseFormField) {
              dispatch({
                type: 'SET_FILE_UPLOAD_FORM_FIELD',
                payload: {
                  id: formFieldId,
                  field: {
                    ...baseFormField,
                    value: {
                      file: null,
                      name: values[0],
                      uuid: values[1],
                    },
                  },
                },
              });
            }
          } else {
            if (baseFormField) {
              let value;
              if (type === 'MULTIPLE_CHECKBOXES') {
                const { items } = baseFormField;
                value = values
                  .map((searchValue) => {
                    return items.find(
                      (item) =>
                        item.value === searchValue || item.name === searchValue
                    )?.itemId;
                  })
                  .filter((value) => !!value);
              } else if (type === 'PICK_LIST') {
                const { items } = baseFormField;
                const searchValue = values[0];
                value = items.find(
                  (item) =>
                    item.value === searchValue || item.name === searchValue
                )?.itemId;
              } else {
                value = values[0];
              }

              dispatch({
                type: 'SET_NON_FILE_UPLOAD_FORM_FIELD',
                payload: {
                  id: formFieldId,
                  field: {
                    ...baseFormField,
                    value: value as any,
                  },
                },
              });
            }
          }
        }
      });
    }

    if (existingWaivers && existingWaivers.length) {
      existingWaivers.forEach((waiver) => {
        if (waivers) {
          const currentWaiver = waivers.find(
            ({ waiverId }) => waiver.waiverId === waiverId
          );
          if (currentWaiver) {
            dispatch({
              type: 'SET_SIGNED_WAIVERS',
              payload: {
                id: waiver.waiverId.toString(),
                signed: true,
              },
            });
          }
        }
      });
    }
  };

  const updateWithExistingRegistration = async (userId: string) => {
    if (!ctx) {
      return;
    }

    return getExistingUserRegistration({
      registrationType: ctx.type,
      userId: parseInt(userId),
      programId: parseInt(ctx.programId.toString()),
      teamIdOg: ctx.teamId ? parseInt(ctx.teamId.toString()) : undefined,
      roleId:
        ctx.roleId && !isNaN(parseInt(ctx.roleId))
          ? parseInt(ctx.roleId)
          : undefined,
    }).then((existingRegistration) => {
      updateStateFromRegistration(existingRegistration);
    });
  };

  const [updated, setUpdated] = useState(false);
  useEffect(() => {
    if (data && ctx && isPlayerRegistration(ctx.type) && !updated) {
      const userId = localStorage.getItem('selectedPlayer');
      if (userId) {
        updateWithExistingRegistration(userId).catch((e) => console.error(e));
        setUpdated(true);
      }
    }
    if (data && ctx && isStaffRegistration(ctx.type) && !updated) {
      if (data?.loggedInUserId) {
        updateWithExistingRegistration(data.loggedInUserId.toString()).catch(
          (e) => console.error(e)
        );
        setUpdated(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx, data, updated]);

  if (registrationError) {
    // This error message will be treated in another ticket
    throw new Error('Unable to fetch registrant data');
  }

  if (
    !data ||
    isLoading ||
    siteSettingsIsLoading ||
    ngbMembershipTypeIsLoading
  ) {
    return (
      <RegistrationContext.Provider value={initialValues}>
        {!data || siteSettingsIsLoading || ngbMembershipTypeIsLoading
          ? null
          : children}
      </RegistrationContext.Provider>
    );
  }

  const {
    decodedData,
    formFields,
    loggedInUserId,
    loggedInUserName,
    masterProgram,
    memberFormFields,
    structuredStateEnabled,
    team,
    waivers,
    feeValue,
  } = data;

  const uploadFile = ({
    file,
    propertyDefinitionId,
    userId,
  }: FileUploadData): Promise<{ uuid: string }> => {
    return uploadFileApi({
      siteId: ctx?.siteId.toString() ?? '',
      propertyDefinitionId,
      userId,
      file: file.file as ArrayBuffer,
      filename: file.name,
    });
  };

  const updateFormFields = ({
    customFields,
    registrationId,
    userId,
  }: FormFieldUpdateData): Promise<{}[]> => {
    return Promise.all(
      customFields.map((field) => {
        const { propertyDefinitionId, type, value } = field;
        // Do not make call to update form field if there is no value
        if (!value) {
          return {};
        }

        let values;
        switch (type) {
          case 'FILE_UPLOAD':
            let uuid = value.uuid;
            if (uuid) {
              values = [
                {
                  registrationId,
                  userId,
                  value: uuid,
                },
              ];
            } else {
              // If the form is not uploaded yet (`uuid` is undefined), upload
              // the file first and then set `values` accordingly.
              if (userId) {
                return uploadFile({
                  file: { file: value.file, name: value.name },
                  propertyDefinitionId: propertyDefinitionId.toString(),
                  userId: userId,
                }).then(({ uuid }) => {
                  let id = registrationId ? { registrationId } : { userId };

                  return updateFormField({
                    siteId: ctx?.siteId.toString() ?? '',
                    formFieldId: propertyDefinitionId.toString(),
                    values: [
                      {
                        ...id,
                        value: uuid,
                      },
                    ],
                  });
                });
              }

              throw new Error('A user id must be supplied to upload a file.');
            }
            break;
          case 'MULTIPLE_CHECKBOXES':
            values = value.map((itemId) => ({
              registrationId,
              userId,
              value: itemId,
            }));
            break;
          default:
            values = [
              {
                registrationId,
                userId,
                value,
              },
            ];
            break;
        }

        return updateFormField({
          siteId: ctx?.siteId.toString() ?? '',
          formFieldId: propertyDefinitionId.toString(),
          values,
        });
      })
    );
  };

  return (
    <RegistrationContext.Provider
      value={{
        ...state,
        dispatch,
        waivers,
        formFields,
        decodedData,
        ctx,
        loggedInUserId,
        loggedInUserName,
        memberFormFields,
        memberProfileSettings: {
          childBirthDateRequired: !!siteSettings?.childBirthDateRequired,
          childEmailEnabled: !!siteSettings?.childEmailEnabled,
        },
        structuredStateEnabled,
        tournamentId: ctx?.programId.toString() ?? '',
        masterProgramId: masterProgram?.id,
        masterProgramName: masterProgram?.name,
        ngbMembershipType,
        hotelLink: masterProgram?.details.hotelLinks,
        registrationId,
        teamName: team?.name ?? '',
        touched,
        setTouched,
        updateFormFields,
        uploadFile,
        hasErrors,
        setHasErrors,
        waiversTouched,
        setWaiversTouched,
        updateWithExistingRegistration,
        roleName,
        registrationOptions,
        setRegistrationId,
        setRegistrationOptions,
        subdomain,
        userRegistrations: userRegistrations ?? [],
        loading: isLoading,
        feeValue,
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
};

export const useRegistration = () => {
  const context = useContext(RegistrationContext);
  const {
    ctx,
    groupAccount,
    nonFileUploadFormFields,
    fileUploadFormFields,
    loggedInUserId,
    loggedInUserName,
    ngbMembershipType,
    selectedPlayer,
    setHasErrors,
    setRegistrationId,
    setWaiversTouched,
    signedWaivers,
    subdomain,
    waivers,
    userRegistrations,
    masterProgramId,
    feeValue,
  } = context;
  const navigate = useNavigate();
  const [isCartLoading, setIsCartLoading] = useState(false);

  const {
    ngTournamentNgbVerification,
    tournamentTeamRegistrationWizardVersion,
    playersAndStaffFees,
  } = useFlags();

  const {
    currentStep,
    formSteps,
    stepNumber,
    numberOfTotalSteps,
    getNextStep,
    getPreviousStep,
    onNextClick,
    onBackClick,
    steps,
  } = useRegistrantSteps(
    ctx?.type,
    context.formFields,
    ngTournamentNgbVerification && ngbMembershipType,
    masterProgramId?.toString() ?? ''
  );

  const [finalizeRegistrations] = useFinalizeRegistrationsMutation();

  const childPlayers: GroupAccountUser[] = (groupAccount?.members ?? [])
    .filter((member: BFFMember) => member.user.type === 'child')
    .map((member: BFFMember) => member.user);

  const adultEmails: string[] = (groupAccount?.members ?? [])
    .filter((member: BFFMember) => member.user.type === 'adult')
    .map((member: BFFMember) => member.user.email);

  const pendingInvitationPlayers: Registration[] = (
    userRegistrations || []
  ).filter((reg) => reg.registrationStatus === 'PENDING_INVITE');

  let name: string;
  if (isPlayerRegistration(ctx?.type)) {
    const player = childPlayers.find((p) => p.id.toString() === selectedPlayer);
    name = `${player?.firstName} ${player?.lastName}`;
  } else {
    name = loggedInUserName;
  }

  const onWaiversSubmit = () => {
    if (!waivers) {
      return null;
    }

    setWaiversTouched(true);
    let hasError = false;

    for (const waiver of waivers) {
      if (!signedWaivers[waiver.waiverId]) {
        hasError = true;
      }
    }

    setHasErrors(hasError);

    if (!hasError && ctx) {
      const registrationFormFields = formatFormFieldsForWorkflow(
        [
          Object.values(nonFileUploadFormFields),
          Object.values(fileUploadFormFields),
        ].flat()
      );

      const registrationWaivers: RegistrationWorkflowWaiver[] = Object.keys(
        signedWaivers
      ).map((waiverId) => ({
        waiverId: parseInt(waiverId),
        waiverType: 'REGISTRATION' as 'REGISTRATION',
      }));

      updateWorkflowRegistration(
        {
          formFields: registrationFormFields,
          name,
          programId: parseInt(ctx.programId.toString()),
          programRole: getProgramRole(ctx.type),
          programStaffId:
            isStaffRegistration(ctx.type) && ctx.roleId
              ? parseInt(ctx.roleId)
              : undefined,
          registeredUserId: isPlayerRegistration(ctx.type)
            ? parseInt(selectedPlayer)
            : loggedInUserId,
          registeringUserId: loggedInUserId,
          registrationType: ctx.type,
          siteId: ctx.siteId,
          teamIdOg: ctx.teamId ? parseInt(ctx.teamId.toString()) : 0,
          waivers: registrationWaivers,
          workflowVersion: Math.trunc(tournamentTeamRegistrationWizardVersion),
        },
        subdomain
      ).then((response) => {
        const registrationId = Object.keys(response.data.outputs.results)[0];
        setRegistrationId(registrationId);
        onNextClick();
      });
    }
  };

  const submitRegistration = async () => {
    if (ctx) {
      const registrationFormFields = formatFormFieldsForWorkflow(
        [
          Object.values(nonFileUploadFormFields),
          Object.values(fileUploadFormFields),
        ].flat()
      );

      const registrationWaivers: RegistrationWorkflowWaiver[] = Object.keys(
        signedWaivers
      ).map((waiverId) => ({
        waiverId: parseInt(waiverId),
        waiverType: 'REGISTRATION' as 'REGISTRATION',
      }));

      const addToCart =
        playersAndStaffFees && feeValue && !isNaN(+feeValue) && +feeValue > 0;
      const payload = {
        finalize: !addToCart,
        formFields: registrationFormFields,
        name,
        programId: parseInt(ctx.programId.toString()),
        programRole: getProgramRole(ctx?.type),
        programStaffId:
          isStaffRegistration(ctx.type) && ctx.roleId
            ? parseInt(ctx.roleId)
            : undefined,
        registeredUserId: isPlayerRegistration(ctx.type)
          ? parseInt(selectedPlayer)
          : loggedInUserId,
        registrationType: ctx.type,
        registeringUserId: loggedInUserId,
        siteId: ctx.siteId,
        teamIdOg: ctx.teamId ? parseInt(ctx.teamId.toString()) : 0,
        waivers: registrationWaivers,
        workflowVersion: Math.trunc(tournamentTeamRegistrationWizardVersion),
      };

      const { data } = await updateWorkflowRegistration(payload, subdomain);
      if (addToCart) {
        setIsCartLoading(true);
        // TODO-EDUARDO: After BFF changes are merged use the proper payload type.
        await finalizeRegistrations({
          registrations: [
            {
              ...(payload as any),
              id: Object.keys(data.outputs.results)[0],
            },
          ],
          siteDomain: subdomain,
          programId: ctx.programId.toString(),
          type: ctx.type,
          userId: loggedInUserId,
        });
        setIsCartLoading(false);
        navigate('/cartSummary');
      } else {
        // onNextClick();
      }
    }
  };

  return {
    ...context,
    tournamentId: ctx?.programId,
    siteId: ctx?.siteId,
    childPlayers,
    adultEmails,
    pendingInvitationPlayers,
    currentStep,
    formSteps,
    stepNumber,
    ngbMembershipType,
    numberOfTotalSteps,
    getNextStep,
    getPreviousStep,
    steps,
    name,
    onNextClick,
    onBackClick,
    onWaiversSubmit,
    showNGBVerification: !!ngTournamentNgbVerification,
    submitRegistration,
    masterProgramId,
    isCartLoading,
  };
};
