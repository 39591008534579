// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.2__/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.93.0_@swc+core@1.7.1_@swc+helpers@0.5.2__/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dKPThwDqCGj9Cbd_q8f3{margin-top:24px;margin-bottom:24px;max-width:920px}.njAVfW_01b28AM42_hNl{display:flex;flex-wrap:wrap;gap:16px;margin:16px 0}.xCtsRXHFXlg1jBBw4AUV{margin-top:32px;margin-bottom:48px}.HZTNod5e_8Yj5FCIB0ac{display:flex;flex-direction:column;gap:8px}@media(min-width: 900px){.HZTNod5e_8Yj5FCIB0ac{flex-direction:row}}.EbnVLPPHTmEWxwDHNUMA{width:100%;flex:.66}.zvC7T6VPE1SfqDSsa_xf{width:100%;flex:.66}.k_3kePYo5K1Ib7kiEJq9{width:100%}@media(min-width: 900px){.k_3kePYo5K1Ib7kiEJq9{flex:.33;margin-left:24px}}`, "",{"version":3,"sources":["webpack://./src/domains/Tournaments/SelectRegistrationType/SelectRegistrationType.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,kBAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,cAAA,CACA,QAAA,CACA,aAAA,CAGF,sBACE,eAAA,CACA,kBAAA,CAGF,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAEA,yBALF,sBAMI,kBAAA,CAAA,CAIJ,sBACE,UAAA,CACA,QAAA,CAGF,sBACE,UAAA,CACA,QAAA,CAGF,sBACE,UAAA,CAEA,yBAHF,sBAII,QAAA,CACA,gBAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectRegistrationContainer": `dKPThwDqCGj9Cbd_q8f3`,
	"selectionSection": `njAVfW_01b28AM42_hNl`,
	"additionalInfoSection": `xCtsRXHFXlg1jBBw4AUV`,
	"additionalInfoContent": `HZTNod5e_8Yj5FCIB0ac`,
	"additionalInfoDivisions": `EbnVLPPHTmEWxwDHNUMA`,
	"additionalInfoTeams": `zvC7T6VPE1SfqDSsa_xf`,
	"additionalInfoRoles": `k_3kePYo5K1Ib7kiEJq9`
};
export default ___CSS_LOADER_EXPORT___;
